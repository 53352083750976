import "./index.scss"
import React, { useState, useEffect } from "react"
import { graphql, useStaticQuery } from "gatsby"
import Img from "gatsby-image"
import Axios from "axios"

import SEO from "../components/seo"

import ArrowIcon from "../images/arrow-icon.svg"
import Background from "../images/website_footer.svg"

const requiredFields = ["name", "email", "message"]

const IndexPage = () => {
  const data = useStaticQuery(graphql`
    query {
      mainImage: file(relativePath: { eq: "image_altcolor_brownline.png" }) {
        childImageSharp {
          fluid(maxWidth: 1024) {
            ...GatsbyImageSharpFluid
          }
        }
      }
    }
  `)

  const [formData, setFormData] = useState({ name: "", email: "", message: "" })
  const [validForm, setValidForm] = useState(false)
  const [contactMessages, setContactMessages] = useState(null)

  useEffect(() => {
    const validateForm = () => {
      let valid = true

      requiredFields.forEach(element => {
        if (!formData[element]) {
          valid = false
        }
      })

      setValidForm(valid)
    }

    validateForm()
  }, [formData])

  const handleReset = () => {
    Array.from(document.querySelectorAll("input")).forEach(
      input => (input.value = "")
    );

    Array.from(document.querySelectorAll("textarea")).forEach(
      textarea => (textarea.value = "")
    );

    setFormData({ name: "", email: "", message: "" });
  };

  const handleFormSubmit = async e => {
    e.preventDefault()

    setContactMessages(null)

    Axios({
      url: `https://aqumkjr4p8.execute-api.eu-west-3.amazonaws.com/dev/contact`,
      method: "post",
      data: JSON.stringify({
        name: formData.name,
        email: formData.email,
        message: formData.message,
      }),
    })
      .then(res => {
        if (res.status === 200) {
          setContactMessages(
            <div className="form-success-message">Succesfully Send</div>
          )

          // Clear inputs
          handleReset();
        }
      })
      .catch(err => {
        setContactMessages(
          <div className="form-error-message">Unsuccessful</div>
        )
      })
  }

  return (
    <>
      <SEO title="Home" />
      <div className="main-area">
        <div className="title-bar">
          <h1 className="title">De Groene Arcade</h1>
          <a href="#contact">Contact</a>
        </div>
        <div className="main-image">
          <Img
            fluid={data.mainImage.childImageSharp.fluid}
            style={{ maxHeight: "100%", width: "100%" }}
            imgStyle={{ objectFit: "contain" }}
            alt="main image"
          />
        </div>
        <span className="divider">
          <a href="#contact">
            <ArrowIcon />
          </a>
        </span>
      </div>
      <div id="contact" className="contact-area">
        <Background />
        <section className="text-section">
          <p>
            <strong>De Groene Arcade</strong>, Dutch for The Green Arcade, is a{" "}
            <strong>sustainable</strong> arcade made from{" "}
            <strong>recycled</strong> materials. We’re in the process of turning
            something that is <strong>undeniably fun</strong>, into something
            that is also making people conscious of our{" "}
            <strong>impact on the environment</strong>. We’re convinced that
            this last part does not have to be a heavy and dreary subject, but
            can also be hopeful and joyful!
          </p>
          <p>
            If you want to join us in our mission, hire us, or if you have
            anything you would like to say to us, please feel free to contact
            us!
          </p>
          <section className="contact-details">
            <h2>Direct contact</h2>
            <span>
              mail:{" "}
              <a href="mailto:info@katpatat.com">
                info@katpatat.com
              </a>
            </span>
            <span>
              tel: <a href="tel:+31624193736">+31(0)624193736</a>
            </span>
            <footer>
              De Groene Arcade is an initiative from{" "}
              <a href="http://katpatat.com">Katpatat</a>. Copyright &copy;{" "}
              {new Date().getFullYear()} Katpatat
            </footer>
          </section>
        </section>
        <section className="contact-form-section">
          <form className="contact-form">
            <span>
              <input
                type="text"
                placeholder="Name"
                onChange={e => {
                  setFormData({ ...formData, name: e.target.value })
                }}
              />
              <input
                type="email"
                placeholder="Email"
                onChange={e => {
                  setFormData({ ...formData, email: e.target.value })
                }}
              />
            </span>
            <textarea
              placeholder="Message"
              onChange={e => {
                setFormData({ ...formData, message: e.target.value })
              }}
            />
            <button
              type="submit"
              disabled={!validForm}
              onClick={e => {
                handleFormSubmit(e)
              }}
            >
              Send
            </button>
            {contactMessages}
          </form>
        </section>
      </div>
    </>
  )
}

export default IndexPage
